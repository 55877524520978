import Vue from "vue"
import { i18n } from "@/config"

const DEFAULT_HIDE_DELAY = 5000
const DEFAULT_AUTO_HIDE = true

const TOAST_DEFAULT_PARAMS = {
  autoHide: DEFAULT_AUTO_HIDE,
  hideDelay: DEFAULT_HIDE_DELAY
}

const successDefaultToastParams = () => ({
  title: i18n.t("success"),
  variant: "success",
  ...TOAST_DEFAULT_PARAMS
})

export const showSuccessToast = text => {
  showToast({ ...successDefaultToastParams(), text })
}

export const showToast = ({
  text,
  title = i18n.t("error"),
  variant = "danger",
  autoHide = DEFAULT_AUTO_HIDE,
  hideDelay = DEFAULT_HIDE_DELAY
}) => {
  const vm = new Vue()
  vm.$bvToast.toast(text, {
    noAutoHide: !autoHide,
    variant,
    title,
    autoHideDelay: hideDelay
  })
}
